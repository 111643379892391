import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_About = _resolveComponent("About")!
  const _component_Product = _resolveComponent("Product")!
  const _component_OurClient = _resolveComponent("OurClient")!
  const _component_ChooseUs = _resolveComponent("ChooseUs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Introduction, { windowWidth: _ctx.windowWidth }, null, 8, ["windowWidth"]),
    _createVNode(_component_About, { windowWidth: _ctx.windowWidth }, null, 8, ["windowWidth"]),
    _createVNode(_component_Product, { windowWidth: _ctx.windowWidth }, null, 8, ["windowWidth"]),
    _createVNode(_component_OurClient, { windowWidth: _ctx.windowWidth }, null, 8, ["windowWidth"]),
    _createVNode(_component_ChooseUs, { windowWidth: _ctx.windowWidth }, null, 8, ["windowWidth"])
  ]))
}