<template>
  <div class="map">
    <div class="column">
      <GoogleMap
        api-key="AIzaSyCVgoGDwXMcKQwK3FKJqMUxHufY_orzK0c"
        style="width: 100%; height: 500px"
        :center="center"
        :zoom="15"
      >
        <CustomMarker :options="{ position: center }">
          <div class="customMarker" />
        </CustomMarker>
        <InfoWindow :options="{ position: center2 }">
          <div class="info-window">
            <label class="info-window-head">PT Pasifik Hoki Indonesia</label>
            <label class="info-label-address"
              >Gold Coast Office, Libery Tower OTB 20B, Jakarta Utara, Daerah
              Khusus Ibukota Jakarta, 14470</label
            >
          </div>
        </InfoWindow>
      </GoogleMap>
    </div>
    <div class="column-data">
      <h4>PT Pasifik Hoki Indonesia</h4>
      <label class="gray-label"
        >A member of Pasifik Sukses Gemilang Group</label
      >
      <div style="margin-top: 16px" class="row">
        <div class="column-info">
          <img alt="Phone" src="@/assets/icon/phone.svg" class="icon-info" />
          <img alt="Email" src="@/assets/icon/email.svg" class="icon-info" />
          <img
            alt="Address"
            src="@/assets/icon/location_pin.svg"
            class="icon-info"
          />
        </div>
        <div class="column-info">
          <label class="label-info"> (081) 11623222</label>
          <label class="label-info"> halo@phisoft.co.id</label>
          <label class="label-info">
            Gold Coast Office, Liberty Tower OTB 20B, Pantai Indah Kapuk, Kota
            Jakarta Utara, Daerah Khusus Ibukota Jakarta, 14470 -
            Indonesia</label
          >
        </div>
      </div>
      <label class="gray-label"
        >Operational Hours: Monday - Friday (09:00 - 16:00)</label
      >
    </div>
  </div>
</template>

<script>
import { GoogleMap, CustomMarker, InfoWindow } from 'vue3-google-map';
export default {
  components: {
    GoogleMap,
    CustomMarker,
    InfoWindow,
  },
  data() {
    return {
      center: { lat: -6.102850094540435, lng: 106.74045341575615 },
      center2: { lat: -6.102050094540435, lng: 106.74045341575615 },
    };
  },
};
</script>

<style>
.map {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
}
.gray-label {
  color: var(--gray);
}

.column-data {
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: left;
  width: 100%;
}

.column-info {
  display: flex;
  flex-direction: column;
}
.customMarker {
  background-color: white;
  border: 10px solid var(--primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.info-window {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--primary);
  padding: 20px;
}

.info-window-head {
  color: white;
}

.info-label-address {
  color: white;
}
button.gm-ui-hover-effect {
  visibility: hidden;
  box-sizing: border-box;
}
.gm-style .gm-style-iw-c {
  padding: 0;
}
.gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}
.icon-info {
  margin-right: 16px;
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
}

.label-info {
  margin-bottom: 10px;
  line-height: 24px;
}
</style>
