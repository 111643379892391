<template>
  <div class="product">
    <div class="product-indicator">
      <label class="product-indicator-label">Our Products</label>
    </div>
    <h3 class="product-heading">Technology of the Future</h3>
    <label class="product-subheading"
      >We are proud to introduce to you our best softwares.</label
    >

    <div
      :class="{
        'product-section': true,
        'product-section-mobile': windowWidth < 1000,
      }"
    >
      <div
        @mouseenter="hover[0] = true"
        @mouseleave="hover[0] = false"
        @click="openOTP"
        :class="{ 'product-container': true, 'container-hover': hover[0] }"
      >
        <img
          alt="OnTime Payroll"
          class="product-image"
          src="@/assets/ontime-payroll.webp"
        />
        <h5 :class="{ 'product-headline': true, 'headline-hover': hover[0] }">
          OnTime Payroll (OTP)
        </h5>
        <div :class="{ 'line-seperator': true, 'line-hover': hover[0] }" />
        <label
          :class="{
            'product-description': true,
            'description-hover': hover[0],
          }"
          >OnTime Payroll is a Software as a Service (SaaS) application that has
          many tools to help manage your Employee Attendance and Payroll.
          Starting from Employee management, work schedules, Permissions,
          Sickness & Leave, Attendance Process, Payroll Process and many other
          features.</label
        >
        <label
          :class="{ 'learn-more-button': true, 'learn-more-hover': hover[0] }"
          ><div :class="{ 'chevron-right': true, 'chevron-hover': hover[0] }" />
          Learn more</label
        >
      </div>
      <div
        @mouseenter="hover[1] = true"
        @mouseleave="hover[1] = false"
        @click="openBIS"
        :class="{ 'product-container': true, 'container-hover': hover[1] }"
      >
        <img
          alt="BisAccounting"
          class="product-image"
          src="@/assets/bisaccounting.webp"
        />
        <h5 :class="{ 'product-headline': true, 'headline-hover': hover[1] }">
          BIS Accounting
        </h5>
        <div :class="{ 'line-seperator': true, 'line-hover': hover[1] }" />
        <label
          :class="{
            'product-description': true,
            'description-hover': hover[1],
          }"
          >BIS Accounting is a Software as a Service (SaaS) application that has
          many tools to help manage your business. Starting from inventory,
          transactions, reports, customers, suppliers and many other
          features.</label
        >
        <label
          :class="{ 'learn-more-button': true, 'learn-more-hover': hover[1] }"
          ><div :class="{ 'chevron-right': true, 'chevron-hover': hover[1] }" />
          Learn more</label
        >
      </div>
      <div
        @mouseenter="hover[2] = true"
        @mouseleave="hover[2] = false"
        @click="openPOS"
        :class="{ 'product-container': true, 'container-hover': hover[2] }"
      >
        <img alt="Phipos" class="product-image" src="@/assets/phipos.webp" />
        <h5 :class="{ 'product-headline': true, 'headline-hover': hover[2] }">
          PhiPOS
        </h5>
        <div :class="{ 'line-seperator': true, 'line-hover': hover[2] }" />
        <label
          :class="{
            'product-description': true,
            'description-hover': hover[2],
          }"
          >PhiPOS is a point-of-sale system that aim to help all merchant in
          growing their businesses. All feature of PhiPOS are run on a real time
          basis using cloud which means you can monitor all data from the
          dashboard from the comfort of your home.</label
        >
        <label
          :class="{ 'learn-more-button': true, 'learn-more-hover': hover[2] }"
          ><div :class="{ 'chevron-right': true, 'chevron-hover': hover[2] }" />
          Learn more</label
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
@Options({
  props: {
    windowWidth: Number,
  },
})
export default class Procuct extends Vue {
  windowWidth: number = window.innerWidth;
  hover: Array<Boolean> = [false, false, false];
  openOTP() {
    window.open('https://www.programgaji.com', '_blank');
  }
  openBIS() {
    window.open('https://www.bisaccounting.com', '_blank');
  }
  openPOS() {
    window.open('https://pos.phisoft.co.id', '_blank');
  }
}
</script>

<style scoped>
.product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
}

.product-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.product-indicator-label {
  color: var(--primary);
  padding-left: 12px;
  position: relative;
}
.product-indicator-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: var(--primary);
  border-radius: 2px;
}
.product-heading {
  margin-bottom: 16px;
}
.product-subheading {
  color: var(--gray);
  margin-bottom: 20px;
}

.product-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1028px) {
  .product-section {
    grid-template-columns: repeat(1, 1fr);
  }
}

.product-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 16px;
  box-shadow: 0 0.4px 2px 0 rgba(37, 37, 37, 0.2);
  box-sizing: border-box;
  height: 100%;
  transition: 100ms;
  cursor: pointer;
}

.container-hover {
  background-color: var(--primary);
  transition: 100ms;
}

.product-headline {
  margin: 0 32px;
}

.headline-hover {
  color: white;
}

.product-description {
  margin: 0 32px;
  color: var(--gray);
}
.description-hover {
  color: white;
}
.product-image {
  height: 156px;
  width: 100%;
  margin: 32px;
  object-fit: contain;
}

.line-seperator {
  background-color: var(--primary);
  margin: 32px 0 32px 32px;
  height: 4px;
}

.line-hover {
  background-color: white;
}

.learn-more-button {
  color: var(--black);
  text-align: center;
  padding: 32px;
  font-weight: 500;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.learn-more-hover {
  color: white;
}
.chevron-right {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 16px;
}

.chevron-right::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(315deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid var(--black);
  border-bottom: 2px solid var(--black);
}

.chevron-hover::before {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.d-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .product-image {
    width: 80%;
  }
}
</style>
