<template>
  <div class="our-client">
    <h3>Our Clients</h3>
    <vueper-slides
      class="no-shadow"
      :visible-slides="windowWidth < 800 ? 4 : 6"
      :slide-ratio="windowWidth < 800 ? 1 / 5 : 1 / 7"
      :dragging-distance="70"
      :gap="windowWidth < 800 ? 6 : 3"
      slide-multiple
      :bullets="false"
      :arrows="false"
      lazy
      lazy-load-on-drag
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image">
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import '@/assets/slide.css';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    windowWidth: Number,
  },
  setup(props) {
    const slides = [
      {
        image: require('@/assets/our-client/vgroup.webp'),
      },
      {
        image: require('@/assets/our-client/bca-finance.webp'),
      },
      {
        image: require('@/assets/our-client/suzuya.webp'),
      },
      {
        image: require('@/assets/our-client/tcc.webp'),
      },
      {
        image: require('@/assets/our-client/ybm-bri.webp'),
      },
      {
        image: require('@/assets/our-client/keb-hana-bank.webp'),
      },
      {
        image: require('@/assets/our-client/hartech.webp'),
      },
      {
        image: require('@/assets/our-client/interpan.webp'),
      },
      {
        image: require('@/assets/our-client/shihlin.webp'),
      },
      {
        image: require('@/assets/our-client/yumeida.webp'),
      },
      {
        image: require('@/assets/our-client/everbright.webp'),
      },
      {
        image: require('@/assets/our-client/pt-pupuk-indonesia-logistik.webp'),
      },
      {
        image: require('@/assets/our-client/solusi-inti-multiteknik.webp'),
      },
      {
        image: require('@/assets/our-client/garuda-vape-store.webp'),
      },
      {
        image: require('@/assets/our-client/pd-rossy.webp'),
      },
      {
        image: require('@/assets/our-client/tea-garden.webp'),
      },
      {
        image: require('@/assets/our-client/chop-buntut.webp'),
      },
    ];
    return {
      slides,
      windowWidth: props.windowWidth,
    };
  },
});
</script>

<style>
.our-client {
  padding: 30px;
}
</style>
